import { useCallback } from 'react';
import {
  FdrDeleteItemFromActiveWishlistMutation,
  useFdrDeleteItemFromActiveWishlistMutation,
} from 'fdr/schemas/mutation/wishlist/fdr-delete-item-from-active-wishlist.generated';
import { refetchQueries } from './fdr-wishlist.constants';

const useRemoveOfferFromActiveWishlist = (
  onCompleted?: (data: FdrDeleteItemFromActiveWishlistMutation) => void
): [(offerId: string) => ReturnType<typeof removeMutation>, boolean] => {
  const [removeMutation, { loading: deleting }] =
    useFdrDeleteItemFromActiveWishlistMutation({
      refetchQueries,
      onCompleted,
    });

  const remove = useCallback(
    (offerId: string) => {
      return removeMutation({ variables: { input: { offerId } } });
    },
    [removeMutation]
  );

  return [remove, deleting];
};

export default useRemoveOfferFromActiveWishlist;
