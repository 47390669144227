import { useCallback } from 'react';
import { FdrWishlistItemFragment } from 'fdr/schemas/fragment/wishlist/fdr-wishlist.generated';
import {
  FdrAddItemToActiveWishlistMutation,
  useFdrAddItemToActiveWishlistMutation,
} from 'fdr/schemas/mutation/wishlist/fdr-add-item-to-active-wishlist.generated';
import { refetchQueries } from './fdr-wishlist.constants';

const useAddOfferToActiveWishlist = (
  onCompleted?: (data: FdrAddItemToActiveWishlistMutation) => void
): [
  (offerId: string, source?: string) => Promise<FdrWishlistItemFragment | null>,
  boolean
] => {
  const [addMutation, { loading }] = useFdrAddItemToActiveWishlistMutation({
    refetchQueries,
    onCompleted,
  });

  const addOffer = useCallback(async (offerId: string, source?: string) => {
    const { data: addData } = await addMutation({
      variables: {
        input: { items: [{ offerId, source }] },
      },
    });

    if (!addData?.fdrAddWishlistItemToActive?.success) return null;

    return (
      addData.fdrAddWishlistItemToActive.wishlist?.wishlistItems?.find(
        item => item.trackedOffer.original.id === offerId
      ) || null
    );
  }, []);

  return [addOffer, loading];
};

export default useAddOfferToActiveWishlist;
