import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { FdrAddItemToActiveWishlistMutation } from 'fdr/schemas/mutation/wishlist/fdr-add-item-to-active-wishlist.generated';
import { useFdrGetActiveWishlistOfferIdsQuery } from 'fdr/schemas/query/wishlist/fdr-get-active-wishlist-offer-ids.generated';
import useAddOfferToActiveWishlist from './fdr-use-add-offer-to-active-wishlist';
import useRemoveOfferFromActiveWishlist from './fdr-use-remove-offer-from-active-wishlist';

/**
 * Enum to define the mode for handling wishlist offers.
 * This is used to differentiate between whether `isActive` is based on a product or an offer.
 */
export enum EWishlistOfferMode {
  /**
   * Represents the **Product** mode.
   * - `isActive` is based on the `productId`.
   */
  Product,

  /**
   * Represents the **Offer** mode.
   * - `isActive` is based on the `offerId`.
   */
  Offer,
}

interface IUseWishlistOfferProps {
  offerId: string;
  productId: string;
  inWishlist?: boolean;
  source?: string;
  onAdd?(): void;
  onRemove?(): void;
  mode?: EWishlistOfferMode;
}

const useWishlistOffer = ({
  offerId,
  productId,
  source,
  inWishlist,
  onAdd,
  onRemove,
  mode = EWishlistOfferMode.Product,
}: IUseWishlistOfferProps) => {
  const client = useApolloClient();
  const { data: activeWishlistData } = useFdrGetActiveWishlistOfferIdsQuery();

  const [isActive, setIsActive] = useState(inWishlist);

  const activeWishlistItems =
    activeWishlistData?.fdrActiveWishlist.wishlist?.wishlistItems || [];
  const activeWishlistItem = activeWishlistItems.find(item => {
    switch (mode) {
      case EWishlistOfferMode.Offer:
        return (
          item.trackedOffer.latest?.id === offerId ||
          item.trackedOffer.original.id === offerId
        );
      case EWishlistOfferMode.Product:
        return item.product.id === productId;
    }
  });

  useEffect(() => {
    if (activeWishlistItem && !isActive) {
      setIsActive(true);
    } else if (!activeWishlistItem && isActive) {
      setIsActive(false);
    }
  }, [activeWishlistItem, isActive]);

  const onAddMutationComplete = useCallback(
    (data: FdrAddItemToActiveWishlistMutation) => {
      // If the user does not have an active wishlist, we need to manually add the newly created one to the Apollo cache
      if (
        !activeWishlistData?.fdrActiveWishlist.wishlist &&
        data.fdrAddWishlistItemToActive?.success &&
        data.fdrAddWishlistItemToActive.wishlist?.wishlistItems?.length
      ) {
        client.cache.modify({
          fields: {
            fdrActiveWishlist() {
              return {
                ...data.fdrAddWishlistItemToActive?.wishlist,
                __typename: 'FdrActiveWishlistResponse' as const,
              };
            },
          },
        });
      }

      onAdd?.();
    },
    [activeWishlistData?.fdrActiveWishlist.wishlist, onAdd]
  );

  const [add, adding] = useAddOfferToActiveWishlist(onAddMutationComplete);

  const [remove, deleting] = useRemoveOfferFromActiveWishlist(onRemove);

  const toggle = useCallback(async () => {
    setIsActive(prev => {
      if (prev && activeWishlistItem) {
        remove(
          activeWishlistItem?.trackedOffer.latest?.id ||
            activeWishlistItem?.trackedOffer.original.id
        );
      }

      if (!prev && !activeWishlistItem) {
        add(offerId, source);
      }

      return !prev;
    });
  }, [add, remove, productId, source, activeWishlistItem, offerId]);

  return {
    isActive,
    loading: adding || deleting,
    toggle,
  };
};

export default useWishlistOffer;
