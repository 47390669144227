import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrGetActiveWishlistOfferIdsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrGetActiveWishlistOfferIdsQuery = {
  __typename?: 'Query';
  fdrActiveWishlist: {
    __typename?: 'FdrActiveWishlistResponse';
    wishlist?: {
      __typename?: 'FdrWishlist';
      id: string;
      wishlistItems?: Array<{
        __typename?: 'FdrWishlistItem';
        product:
          | { __typename?: 'FdrCruise'; id: string }
          | { __typename?: 'FdrHotel'; id: string }
          | { __typename?: 'FdrRoundTrip'; id: string }
          | { __typename?: 'FdrAdventureTravel'; id: string };
        trackedOffer: {
          __typename?: 'FdrTrackedProductOffer';
          status: Types.FdrProductOfferStatus;
          latest?: { __typename?: 'FdrProductOffer'; id: string } | null;
          original: { __typename?: 'FdrProductOffer'; id: string };
        };
      }> | null;
    } | null;
  };
};

export type FdrActiveWishlistLightFragment = {
  __typename?: 'FdrActiveWishlistResponse';
  wishlist?: {
    __typename?: 'FdrWishlist';
    id: string;
    wishlistItems?: Array<{
      __typename?: 'FdrWishlistItem';
      product:
        | { __typename?: 'FdrCruise'; id: string }
        | { __typename?: 'FdrHotel'; id: string }
        | { __typename?: 'FdrRoundTrip'; id: string }
        | { __typename?: 'FdrAdventureTravel'; id: string };
      trackedOffer: {
        __typename?: 'FdrTrackedProductOffer';
        status: Types.FdrProductOfferStatus;
        latest?: { __typename?: 'FdrProductOffer'; id: string } | null;
        original: { __typename?: 'FdrProductOffer'; id: string };
      };
    }> | null;
  } | null;
};

export const FdrActiveWishlistLightFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrActiveWishlistLight' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrActiveWishlistResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wishlistItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trackedOffer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latest' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'original' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrGetActiveWishlistOfferIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrGetActiveWishlistOfferIds' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrActiveWishlist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrActiveWishlistLight' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrActiveWishlistLightFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrGetActiveWishlistOfferIdsQuery__
 *
 * To run a query within a React component, call `useFdrGetActiveWishlistOfferIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrGetActiveWishlistOfferIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrGetActiveWishlistOfferIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrGetActiveWishlistOfferIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrGetActiveWishlistOfferIdsQuery,
    FdrGetActiveWishlistOfferIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrGetActiveWishlistOfferIdsQuery,
    FdrGetActiveWishlistOfferIdsQueryVariables
  >(FdrGetActiveWishlistOfferIdsDocument, options);
}
export function useFdrGetActiveWishlistOfferIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrGetActiveWishlistOfferIdsQuery,
    FdrGetActiveWishlistOfferIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrGetActiveWishlistOfferIdsQuery,
    FdrGetActiveWishlistOfferIdsQueryVariables
  >(FdrGetActiveWishlistOfferIdsDocument, options);
}
export type FdrGetActiveWishlistOfferIdsQueryHookResult = ReturnType<
  typeof useFdrGetActiveWishlistOfferIdsQuery
>;
export type FdrGetActiveWishlistOfferIdsLazyQueryHookResult = ReturnType<
  typeof useFdrGetActiveWishlistOfferIdsLazyQuery
>;
export type FdrGetActiveWishlistOfferIdsQueryResult = Apollo.QueryResult<
  FdrGetActiveWishlistOfferIdsQuery,
  FdrGetActiveWishlistOfferIdsQueryVariables
>;
