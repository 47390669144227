import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import FdrWishlistLoginRecommenderForm from './fdr-wishlist-login-recommender-form';

const FdrWishlistLoginRecommenderModalWrapper = styled(WhiteBoxModal)(
  ({ theme: { FONT_SIZE, modals } }) =>
    sx2CssThemeFn({
      '.modal-wrapper': {
        ...modals.mediumModalWrapper,
        alignSelf: 'center',
        mx: [3, 0],
        '.modal-title': FONT_SIZE.S,
      },
      '.modal-body': { px: 4, pt: 0, pb: 4 },
      '.modal-header': { border: 'none', px: 4, py: 4 },
    })
);

interface IFdrWishlistLoginRecommenderModalProps {
  onClose: () => void;
  show: boolean;
}

const FdrWishlistLoginRecommenderModal: FC<IFdrWishlistLoginRecommenderModalProps> =
  ({ onClose, show }) => {
    const [t] = useTranslation('wishlist');
    const { channelType } = useAuthentication();

    if (channelType !== AuthChannelType.B2CAnonymous) return null;

    return (
      <FdrWishlistLoginRecommenderModalWrapper
        name="WishlistLoginRecommenderForm"
        title={t('loginRecommender.title')}
        show={show}
        onClose={onClose}
      >
        <FdrWishlistLoginRecommenderForm />
      </FdrWishlistLoginRecommenderModalWrapper>
    );
  };

export default FdrWishlistLoginRecommenderModal;
