import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import useWishlistOffer, {
  EWishlistOfferMode,
} from 'fdr/components/candidate/fdr-wishlist/fdr-use-wishlist-offer';

const WISHLIST_LOGIN_RECOMMENDER_COOKIE_NAME =
  'WISHLIST_LOGIN_RECOMMENDER_SHOWED';
const ONE_MONTH = 30;

interface IUseWishlistOfferWithLoginRecommenderProps {
  offerId: string;
  productId: string;
  inWishlist?: boolean;
  source?: string;
  onAdd?(): void;
  onRemove?(): void;
  mode?: EWishlistOfferMode;
}

const useWishlistOfferWithLoginRecommender = ({
  offerId,
  productId,
  inWishlist,
  source,
  onAdd,
  onRemove,
  mode,
}: IUseWishlistOfferWithLoginRecommenderProps) => {
  const { channelType } = useAuthentication();
  const isB2CAnonymous = channelType === AuthChannelType.B2CAnonymous;

  const {
    isActive,
    loading,
    toggle: originalToggle,
  } = useWishlistOffer({
    offerId,
    productId,
    source,
    inWishlist,
    onAdd,
    onRemove,
    mode,
  });

  const [
    isRecommenderModalShown,
    setRecommenderModalOpen,
    setRecommenderModalClose,
  ] = useToggleState(false);

  const toggle = useCallback(() => {
    originalToggle();

    if (!isB2CAnonymous || Cookies.get(WISHLIST_LOGIN_RECOMMENDER_COOKIE_NAME))
      return;

    setRecommenderModalOpen();
    Cookies.set(WISHLIST_LOGIN_RECOMMENDER_COOKIE_NAME, 'true', {
      expires: ONE_MONTH,
    });
  }, [isB2CAnonymous, originalToggle]);

  return {
    isActive,
    loading,
    toggle,
    isRecommenderModalShown,
    setRecommenderModalOpen,
    setRecommenderModalClose,
  };
};

export default useWishlistOfferWithLoginRecommender;
