import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { LOGIN_REDIRECT_PAGE_PARAM_NAME } from '@hotelplan/libs.login-redirect';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { LoginLink } from 'fdr/components/local/fdr-wishlist/fdr-wishlist-login-router-link';
import {
  ELoginClickSource,
  trackLoginClick,
} from 'fdr/components/local/fdr-wishlist/fdr-wishlist.tracking';

const ContentWrapper = styled.figure(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.LARGE,
    mb: 4,
    li: {
      listStyle: 'none',
      ml: '24px',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        left: '-14px',
        top: ['10px', '14px'],
        width: '5px',
        height: '5px',
        backgroundColor: 'black',
        borderRadius: '50%',
      },
    },
  })
);

const ForwardMyAccountButton = styled(BsButton)(
  sx2CssThemeFn({
    width: '100%',
    mb: [3, 0],
  })
);

const FdrWishlistLoginRecommenderForm = () => {
  const [t, { language }] = useTranslation('wishlist');

  const query = {
    [LOGIN_REDIRECT_PAGE_PARAM_NAME]: window.location.href,
  };

  return (
    <div>
      <ContentWrapper>
        <figcaption>{t('loginRecommender.listTitle')}</figcaption>
        <ul>
          {[...Array(4)].map((_, index) => (
            <li key={index}>{t(`loginRecommender.listText${index + 1}`)}</li>
          ))}
        </ul>
      </ContentWrapper>
      <LoginLink
        language={language}
        query={new URLSearchParams(query).toString()}
      >
        <ForwardMyAccountButton
          onClick={() => {
            trackLoginClick(ELoginClickSource.WISHLIST_MODAL);
          }}
        >
          {t('wishlist:noAuthNotification.loginLink')}
        </ForwardMyAccountButton>
      </LoginLink>
    </div>
  );
};

export default FdrWishlistLoginRecommenderForm;
