import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrCo2EmissionWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '100%',
  })
);

export const FdrCo2EmissionIcon = styled(BsIcon)(
  sx2CssThemeFn({
    fill: 'none',
    mr: 2,
  })
);

export const FdrCo2EmissionTotal = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({ ...FONT_SIZE.MEDIUM, mr: 1 })
);

export const FdrCo2EmissionInfoIconWrap = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    display: 'flex',
  })
);

export const FdrCo2EmissionInfoIcon = styled(BsIcon)(
  sx2CssThemeFn({
    fill: 'none',
    color: 'darkGray',
    width: '16px',
    height: '16px',
  })
);
