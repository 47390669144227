import { useRouter } from 'next/router';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH } from '@hotelplan/fdr.lib.ident.auth-config';

export function LoginLink({
  children,
  language,
  query,
  className,
}: PropsWithChildren<{
  language: string;
  query?: string;
  className?: string;
}>) {
  const { push } = useRouter();
  const loginPath = `${SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH}?lang=${language}${
    query ? `&${query}` : ''
  }`;

  return (
    <a
      className={className}
      href={'#'}
      onClick={event => {
        event.preventDefault();
        push(loginPath);
      }}
    >
      {children}
    </a>
  );
}

export const LoginButtonLink = styled(LoginLink)(
  ({ theme }) => theme.buttons.primary,
  {
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    ':hover': {
      color: 'white',
    },
  }
);
