import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React from 'react';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { IFdrCo2EmissionCommonProps } from 'fdr/components/local/fdr-co2-emission/fdr-co2-emission.types';
import {
  FdrCo2EmissionWrap,
  FdrCo2EmissionIcon,
  FdrCo2EmissionInfoIcon,
  FdrCo2EmissionInfoIconWrap,
  FdrCo2EmissionTotal,
} from './fdr-co2-emission.styles';

const FdrCo2EmissionTooltip = dynamic(() =>
  import('fdr/components/local/fdr-co2-emission/fdr-co2-emission-tooltip').then(
    module => module.FdrCo2EmissionTooltip
  )
);

interface IFdrCo2EmissionProps extends IFdrCo2EmissionCommonProps {
  className?: string;
}

export const FdrCo2Emission: React.FC<IFdrCo2EmissionProps> = ({
  flightEmission,
  hotelEmission,
  className,
}) => {
  const [t] = useTranslation('common');
  const [isShowTooltip, showTooltip, closeTooltip] = useToggleState(false);

  const totalEmission = flightEmission + hotelEmission;

  return (
    <FdrCo2EmissionWrap
      className={className ? className : 'co2-emissions-wrap'}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        showTooltip();
      }}
    >
      <FdrCo2EmissionIcon name="co2emissions" />
      <FdrCo2EmissionTotal
        dangerouslySetInnerHTML={{
          __html: t('common:co2_emission', {
            emission: totalEmission,
          }),
        }}
      />
      <FdrCo2EmissionInfoIconWrap>
        {isShowTooltip ? (
          <FdrCo2EmissionTooltip
            flightEmission={flightEmission}
            hotelEmission={hotelEmission}
            onClose={closeTooltip}
          />
        ) : null}
        <FdrCo2EmissionInfoIcon name="emissioninfo" />
      </FdrCo2EmissionInfoIconWrap>
    </FdrCo2EmissionWrap>
  );
};
