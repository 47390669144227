import dynamic from 'next/dynamic';
import React from 'react';

/***
 *  Way to avoid hydration errors
 ***/
export const FdrLazyRating = dynamic<{
  testId?: string;
  maxRating?: number;
  rating: number;
  ratingContent?: React.ReactNode;
  remainRatingContent?: React.ReactNode;
  halfWayRatingContent?: React.ReactNode;
  className?: string;
}>(
  () =>
    import('@hotelplan/components.common.rating').then(module => module.Rating),
  { ssr: false }
);
