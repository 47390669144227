import { track } from '@hotelplan/libs.tracking-events';

export enum ELoginClickSource {
  WISHLIST = 'wishlist',
  WISHLIST_MODAL = 'wishlist.modal',
}

export const trackLoginClick = (source: ELoginClickSource) => {
  track({
    event: `🚀 click.login`,
    eventMetadata: { source },
    _clear: true,
  });
};
